import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./footer";
import Navigation from "./navigation";

const Layout = ({ children, isDefault = true }) => {
	return (
		<>
			<Navigation isDefault={isDefault} />
			<CookieBanner url="/cookie-notice" />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
