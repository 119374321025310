import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import PostHeader from "../components/post-header";
import Cta17 from "../components/Cta17";
import SafeHtmlParser from "../components/SafeHtmlParser";
import { IoIosArrowForward } from "react-icons/io";
import LayoutNew from "../components/LayoutNew";
import Sticky from "react-stickynode";

const PostTemplate = ({
	data: { post, postsInCategory, latestPosts, site, sideBox, latestWebinar },
}) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${site.siteMetadata.siteUrl}/resources`,
					id: `${site.siteMetadata.siteUrl}/resources`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/resources/${post.slug}`,
					id: `${site.siteMetadata.siteUrl}/resources/${post.slug}`,
				},
			},
		],
	};

	// Combine the category posts with the latest posts if there are not enough in the same category
	const combinedPosts = [
		...postsInCategory.nodes,
		...latestPosts.nodes.filter(
			(latestPost) =>
				!postsInCategory.nodes.some(
					(postInCategory) => postInCategory.id === latestPost.id
				)
		),
	].slice(0, 3);

	return (
		<LayoutNew>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.title}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${site.siteMetadata.siteUrl}/${post.slug}`,
					title: `${post.seoFields?.opengraphTitle}`,
					description: `${post.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.sourceUrl}`,
							width: `${post.seoFields?.image?.mediaDetails.width}`,
							height: `${post.seoFields?.image?.mediaDetails.height}`,
							alt: `${post.seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			<PostHeader
				heading={post.blogFields.heading}
				backgroundImage={post.blogFields.backgroundImage}
				smallHeading={post.blogFields.smallHeading}
			/>

			<section id="bottom-boundary">
				<Container>
					<Row className="pb-4 pt-6 g-md-5">
						<Col className="position-relative blog-post-content" lg={8}>
							<SafeHtmlParser htmlContent={post.content} />
						</Col>
						<Col lg={4}>
							<Sticky enabled={true} top={80} bottomBoundary="#bottom-boundary">
								<div
									className="p-4 text-center"
									style={{
										borderRadius: "12px",
										border:
											"1px solid var(--brand-purple-30, rgba(49, 26, 123, 0.30))",
									}}
								>
									<p className="inter-semibold pb-0 mb-2 fs-6">
										{sideBox.sideBoxFields.box.smallHeading}
									</p>
									<div className="text-primary mb-3">
										<h3 className="text-primary fs-4 inter-bold">
											{sideBox.sideBoxFields.box.heading}
										</h3>
									</div>
									<p style={{ fontSize: "90%" }}>
										{sideBox.sideBoxFields.box.body}
									</p>
									<Button
										size="lg"
										className="w-100 mt-2 w-md-auto w-lg-100 inter-medium"
										variant="primary"
										href={sideBox.sideBoxFields.box.button.url}
									>
										{sideBox.sideBoxFields.box.button.title}
									</Button>
								</div>
							</Sticky>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="mb-5">
						<Col className="mt-lg-5">
							<p className="inter-semibold pb-0 mb-2 fs-6">Insights</p>
							<div className="text-primary mb-3">
								<h2 className="text-primary inter-bold">Recommended blogs</h2>
							</div>
							<p>
								Want to learn more? Read another one of our insightful blogs
								below:
							</p>
						</Col>
					</Row>
					<Row className="g-lg-5 g-3">
						{combinedPosts.map((postOther) => (
							<Col md={6} lg={4} key={postOther.slug} className="mb-4">
								<div>
									<div
										style={{ overflow: "hidden" }}
										className="image-container square "
									>
										<GatsbyImage
											className="w-100"
											image={
												postOther.blogFields.featuredImage.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={postOther.blogFields.featuredImage.altText}
										/>
									</div>

									<div className="my-3">
										<div className="card-category bg-light-purple d-inline-block me-3 p-1 px-2">
											<p
												style={{ fontSize: "14px" }}
												className="py-0 my-0 inter-semibold text-uppercase"
											>
												{postOther.categories.nodes[0].name}
											</p>
										</div>
									</div>

									<div>
										<h3 className="inter-bold mb-3 fs-4 card-title">
											{postOther.title}
										</h3>
									</div>

									<div className="card-meta">
										{postOther.dateGmt}
										{postOther.blogFields.numberOfMinRead && (
											<span>
												<span className="mx-1"> • </span>
												<span className="inter-regular">
													{postOther.blogFields.numberOfMinRead} min read
												</span>
											</span>
										)}
									</div>
									<div className="excerpt-3-lines my-2">
										<SafeHtmlParser htmlContent={postOther.excerpt} />
									</div>
									<Button
										className="text-decoration-none inter-semibold ps-0 pt-0"
										variant="link"
										href={`/resources/${postOther.slug}`}
									>
										Read more <IoIosArrowForward />
									</Button>
								</div>
							</Col>
						))}
					</Row>

					<Row className="mt-4">
						<Col className="text-center">
							<Button
								size="lg"
								className=" w-100 w-md-auto mt-3 inter-medium"
								variant="outline-primary"
								as={Link}
								to="/resources"
							>
								View all
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<Cta17
				latestWebinar={latestWebinar.latestWebinarFields.link}
				data={sideBox.sideBoxFields.banner}
			/>
			{/* Inline CSS for Styling */}
			<style jsx>{`
				.excerpt-3-lines {
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					word-wrap: break-word;
				}

				.excerpt-3-lines p {
					font-size: 14px !important;
					color: #4d4d4d;
				}

				.card-title {
					min-height: 58px;
					display: flex;
					align-items: start;
					justify-content: start;
				}

				.blog1-section .text-center {
					margin-bottom: 20px;
				}

				.blog1-section h2 {
					margin-bottom: 10px;
					font-size: 2.5rem;
					color: #333;
				}

				.blog1-section h3 {
					margin-bottom: 20px;
					font-size: 1.75rem;
					color: #555;
				}

				.blog1-section h4 {
					font-size: 2rem;
					margin-bottom: 20px;
				}

				.blog1-section .card {
					border: none;
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
				}

				.blog1-section .card-category {
					font-size: 0.875rem;
					margin-bottom: 10px;
					text-transform: uppercase;
				}

				.blog1-section .card-title {
					font-size: 1.5rem;
					margin-bottom: 10px;
				}

				.blog1-section .card-text {
					font-size: 1rem;
					margin-bottom: 20px;
				}

				.blog1-section .card-meta {
					font-size: 0.875rem;
					color: #888;
					margin-bottom: 15px;
				}

				.blog1-section .btn-outline-secondary {
					border-color: #6c757d;
					color: #6c757d;
				}
			`}</style>
		</LayoutNew>
	);
};

export default PostTemplate;

export const pageQuery = graphql`
	query BlogPostById($id: String!, $categoryId: String!) {
		latestWebinar: wpLatestWebinar(title: { eq: "Latest Webinar" }) {
			latestWebinarFields {
				link
			}
		}
		sideBox: wpBlogSideBox(title: { eq: "Side Box" }) {
			id
			sideBoxFields {
				box {
					body
					smallHeading
					heading
					button {
						url
						title
					}
				}
				banner {
					body
					heading
					button1 {
						title
						url
					}
				}
			}
		}
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			seoFields {
				fieldGroupName
				localBusinessSchema
				metaDescription
				opengraphDescription
				opengraphTitle
				title
			}
			content
			title
			blogFields {
				heading
				smallHeading
				backgroundImage {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				featuredImage {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			categories {
				nodes {
					id
					name
				}
			}
		}

		postsInCategory: allWpPost(
			filter: {
				categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
				id: { ne: $id }
			}
			sort: { order: DESC, fields: dateGmt }
			limit: 3
		) {
			nodes {
				id
				dateGmt(formatString: "MMMM YY")
				title
				excerpt
				slug
				blogFields {
					numberOfMinRead
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				categories {
					nodes {
						id
						name
					}
				}
			}
		}

		latestPosts: allWpPost(
			filter: { id: { ne: $id } }
			sort: { order: DESC, fields: dateGmt }
			limit: 3
		) {
			nodes {
				id
				dateGmt(formatString: "MMMM YY")
				title
				excerpt
				slug
				blogFields {
					numberOfMinRead
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				categories {
					nodes {
						id
						name
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
