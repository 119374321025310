import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import SafeHtmlParser from "../components/SafeHtmlParser";
import { BgImage } from "gbimage-bridge";

const PostHeader = ({ heading, smallHeading, backgroundImage }) => {
	return (
		<section>
			<BgImage
				alt=""
				className="w-100 position-relative py-5 py-lg-9"
				image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
			>
				<div
					style={{ background: "#311A7B80", zIndex: -1 }}
					className="w-100 h-100 position-absolute start-0 top-0"
				></div>
				<Container style={{ zIndex: 1 }}>
					<Row className="justify-content-center">
						<Col lg={8} className="text-white text-center">
							<p className="inter-semibold pb-0 mb-2 fs-6">{smallHeading}</p>
							<div className="bold-yellow">
								<SafeHtmlParser htmlContent={heading} />
							</div>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</section>
	);
};

export default PostHeader;
