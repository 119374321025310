import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SafeHtmlParser from "../components/SafeHtmlParser";

const Cta17 = ({ data, latestWebinar }) => {
	const { heading, body, button1 } = data;
	return (
		<section className="py-5 py-lg-7" style={{ background: "#311A7B8C" }}>
			<Container>
				<Row className="text-white">
					<Col md={6}>
						<SafeHtmlParser htmlContent={heading} />
					</Col>
					<Col md={6}>
						<div className="pb-3">
							<SafeHtmlParser htmlContent={body} />
						</div>
						{button1?.title?.length > 0 && (
							<Button
								size="lg"
								className="px-4 py-2 w-100 w-lg-auto inter-medium border-0 bg-white text-primary"
								href={button1.url}
								variant="white"
							>
								{button1.title}
							</Button>
						)}
						<Button
							size="lg"
							className="ms-xl-3 w-100 w-lg-auto mt-3 inter-medium mt-xl-0"
							href={latestWebinar}
							target="_blank"
							rel="noopener noreferrer"
							variant="outline-white"
						>
							Register for webinar
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Cta17;
